import { Controller } from '@hotwired/stimulus';
import { toggleCSSClasses } from '../js/utils';

export default class extends Controller {
    static targets = [
        'navLink',

        'menuDestinations',
        'menuDestinationLink',
        'subMenuDestination',
        'destinationSubItemLink',

        'menuThemes',
        'menuThemesLink',
        'subMenuThemes',
        'themeSubItemLink',

        'menuConcept',
    ];

    selectedTarget;

    connect() {
        const mainNavElement = document.getElementById('front-banner-menu');
        // On click anywhere else than banner, close menu
        document.body.addEventListener('click', (event) => {
            event.stopPropagation();

            if (
                !mainNavElement.contains(event.target) &&
                !this.menuDestinationsTarget.contains(event.target) &&
                !this.menuThemesTarget.contains(event.target) &&
                !this.menuConceptTarget.contains(event.target)
            ) {
                this.closeAllMenus();
            }
        });

        // ACTION FUNCTIONS
        const displaySubMenu = (target, selectedTarget) => {
            if (target.dataset['subMenuTarget']) {
                toggleCSSClasses(
                    document.getElementById(target.dataset['subMenuTarget']),
                    'block',
                    'hidden',
                );
            }

            // remove previous hovered link
            if (selectedTarget) {
                selectedTarget.classList.remove('text-content-brand');
                selectedTarget.classList.remove('font-semibold');
                const selectedTargetSvgElement =
                    selectedTarget.getElementsByTagName('svg')[0];
                selectedTargetSvgElement.classList.add('opacity-0');
            }
            // color the hovered link
            target.classList.add('text-content-brand', 'font-semibold');
            const svgElement = target.getElementsByTagName('svg')[0];
            svgElement.classList.remove('opacity-0');
        };

        const displaySubMenuImage = (subItemTarget) => {
            const photoElement = document.getElementById(
                subItemTarget.dataset['photo'],
            );
            const photoImg = photoElement.children[0];

            subItemTarget.addEventListener('mouseover', () => {
                photoElement.classList.add('!opacity-100');
                photoImg.src = photoImg.dataset['src'];
            });
            subItemTarget.addEventListener('mouseleave', () => {
                photoElement.classList.remove('!opacity-100');
            });
        };

        // LISTENERS
        // desti
        this.menuDestinationLinkTargets.forEach((target) => {
            target.addEventListener('mouseover', () => {
                // first close every submenu
                this.closeAllSubMenus('subMenuDestination');
                displaySubMenu(target, this.selectedTarget);
                this.selectedTarget = target;
            });
        });
        // desti submenu
        this.destinationSubItemLinkTargets.forEach((subItemTarget) => {
            displaySubMenuImage(subItemTarget);
        });

        // sports
        this.menuThemesLinkTargets.forEach((target) => {
            target.addEventListener('mouseover', () => {
                // first close every submenu
                this.closeAllSubMenus('subMenuThemes');
                displaySubMenu(target, this.selectedTarget);
                this.selectedTarget = target;
            });
        });

        // sport submenu
        this.themeSubItemLinkTargets.forEach((subItemTarget) => {
            displaySubMenuImage(subItemTarget);
        });
    }

    /**
     * Open/close menu
     * @param event.params.ref
     */
    toggle(event) {
        event.preventDefault();
        const targetValue = event.params.ref;

        // close all except toggled menu
        this.closeAllMenus(targetValue);

        // toggle targeted menu
        if (this[targetValue + 'Target'].classList.contains('hidden')) {
            this[targetValue + 'Target'].classList.remove('hidden');
            toggleCSSClasses(event.target, 'font-semibold', 'underline');
        } else {
            this.closeAllSubMenus();
            this[targetValue + 'Target'].classList.add('hidden');
            event.target.classList.remove('font-semibold', 'underline');
        }
    }

    /**
     * Display subMenu concerned by associated parent menu hovered on
     * @param event `ref` value pointing to the subMenu target
     */
    toggleSubMenu(event) {
        event.preventDefault();

        const targetValue = event.params.ref;
        toggleCSSClasses(this[targetValue + 'Target'], 'hidden');
    }

    /**
     * Close all menus except the one currently displayed
     * @param targetedMenu menuXXXXX
     */
    closeAllMenus(targetedMenu) {
        if (
            targetedMenu !== 'menuDestinations' &&
            !this.menuDestinationsTarget.classList.contains('hidden')
        ) {
            this.menuDestinationsTarget.classList.add('hidden');
        }
        if (
            targetedMenu !== 'menuThemes' &&
            !this.menuThemesTarget.classList.contains('hidden')
        ) {
            this.menuThemesTarget.classList.add('hidden');
        }
        if (
            targetedMenu !== 'menuConcept' &&
            !this.menuConceptTarget.classList.contains('hidden')
        ) {
            this.menuConceptTarget.classList.add('hidden');
        }

        this.navLinkTargets.forEach((navLink) => {
            navLink.classList.remove('font-semibold', 'underline');
        });
    }

    /**
     * Close all submenus except the one currently hovered on
     * @param subMenuTargets subMenuXXXX
     */
    closeAllSubMenus(subMenuTargets) {
        if (subMenuTargets == null) {
            this.subMenuDestinationTargets.forEach((subMenu) => {
                if (subMenu.classList.contains('block'))
                    toggleCSSClasses(subMenu, 'hidden', 'block');
            });
            this.subMenuThemesTargets.forEach((subMenu) => {
                if (subMenu.classList.contains('block'))
                    toggleCSSClasses(subMenu, 'hidden', 'block');
            });
        } else {
            this[subMenuTargets + 'Targets'].forEach((subMenu) => {
                if (subMenu.classList.contains('block'))
                    toggleCSSClasses(subMenu, 'hidden', 'block');
            });
        }
    }
}
