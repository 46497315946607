import { Controller } from '@hotwired/stimulus';
import Splide from '@splidejs/splide';

/*
 * Specific carousel that
 */
export default class extends Controller {
    static splide;
    static values = {
        config: Object,
    };

    initialConfig = {
        focus: 'center',
        mediaQuery: 'min',
        breakpoints: {
            1300: {
                destroy: true,
            },
            768: {
                padding: '0',
            },
        },
        autoWidth: true,
        autoHeight: true,
        gap: '16px',
        padding: '20px',
        arrows: false,
        pagination: false,
        flickPower: 100,
        rewind: true,
        rewindSpeed: 1000,
        rewindByDrag: true,
    };

    connect() {
        console.log(this.configValue);
        this.splide = new Splide(`#carousel-mosaic`, {
            ...this.initialConfig,
            ...this.configValue,
        }).mount();
    }
}
