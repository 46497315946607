import { Controller } from '@hotwired/stimulus';
import { toggleCSSClasses } from '../js/utils';

// FIXME Tailwind negative transform values not working,
// had to use inline style injection
export default class extends Controller {
    static targets = [
        'rootInner', // top level parent div, used to expand menu to full height

        /// HEADER ELEMENTS
        'burgerIcon',
        'closeIcon',

        'menuHeader', // control header content (logo, or nav selection)
        'menuHeaderSelection', // header when user has selected submenu
        'menuHeaderSelectionName', // div tag where we put selected menu/submenu

        // LIST
        'menuListWrapper', // Top level div wrapper, display menu list when opened
        'mainList', // <ul> parent element of menu list

        'menuDestinations',
        'subMenuDestinations',

        'menuThemes',
        'subMenuThemes',

        'menuBlog',
    ];
    static values = {
        // how deep in the menu
        selectionStep: { type: Number, default: 0 },

        selectedMenuName: { type: String, default: '' },
        selectedMenuTargetRef: { type: String, default: '' },

        selectedSubMenuName: { type: String, default: '' },
        selectedSubMenuTargetRef: { type: String, default: '' },
        selectedSubMenuId: { type: String, default: '' },
    };

    connect() {
        /* see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    // Open close the menu
    toggle(event) {
        event.preventDefault();

        const flashMessage = document.getElementById('front-banner-flash');
        if (isOpenValue) {
            toggleCSSClasses(document.body, 'overflow-hidden', 'fixed');
            if (flashMessage) toggleCSSClasses(flashMessage, 'hidden');
        } else {
            toggleCSSClasses(document.body, 'overflow-hidden', 'fixed');
            if (flashMessage) toggleCSSClasses(flashMessage, 'hidden');
        }

        const isOpenValue = this.burgerIconTarget.hidden;

        // svg icons
        this.burgerIconTarget.hidden = isOpenValue ? false : true;
        this.closeIconTarget.hidden = isOpenValue ? true : false;
        this.menuListWrapperTarget.hidden = isOpenValue ? true : false;

        // open menu full height
        toggleCSSClasses(this.rootInnerTarget, 'is-header-open');

        toggleCSSClasses(
            this.menuListWrapperTarget,
            'flex',
            'flex-1',
            'w-full',
        );

        toggleCSSClasses(this.menuHeaderTarget, 'absolute', 'relative');

        if (!window.location.pathname.startsWith('/mon-compte')) {
            toggleCSSClasses(
                this.menuHeaderTarget,
                'fill-white',
                'fill-content-neutral',
            );
        }
    }

    /**
     * Main menu selection (destinations, themes, blog...)
     * @param event.params.menuTargetRef
     * @param event.params.menuName
     */
    handleMenuSelection(event) {
        event.preventDefault();

        this.selectionStepValue = 1;
        this.selectedMenuNameValue = event.params.menuName;
        this.selectedMenuTargetRefValue = event.params.menuTargetRef;

        //change header
        toggleCSSClasses(this.menuHeaderTarget, 'hidden');
        toggleCSSClasses(this.menuHeaderSelectionTarget, 'hidden', 'flex');
        this.menuHeaderSelectionNameTarget.innerText =
            this.selectedMenuNameValue;

        // animate main list
        this.mainListTarget.style.transform = 'translateX(-100vw)';

        const selectedMenuTarget =
            this[this.selectedMenuTargetRefValue + 'Target'];

        selectedMenuTarget.style.transform = 'translateX(0)';

        toggleCSSClasses(selectedMenuTarget, 'w-0', 'w-full');
    }

    /**
     * Submenu selection (Countries, regions, sports...)
     * @param event.params.subMenuTargetRef
     * @param event.params.subMenuId
     * @param event.params.subMenuName
     */
    handleSubmenuSelection(event) {
        event.preventDefault();

        this.selectionStepValue = 2;

        this.selectedSubMenuNameValue = event.params.subMenuName;
        this.selectedSubMenuTargetRefValue = event.params.subMenuTargetRef;
        this.selectedSubMenuId = event.params.subMenuId;
        const selectedSubMenuTarget =
            this[this.selectedSubMenuTargetRefValue + 'Target'];

        // change header title
        this.menuHeaderSelectionNameTarget.innerText =
            this.selectedSubMenuNameValue;

        // animate menu and hide it
        selectedSubMenuTarget.style.transform = 'translateX(-100vw)';

        const subitem = document.getElementById(this.selectedSubMenuId);
        if (subitem) {
            subitem.style.transform = 'translateX(0)';
        }
    }

    // go back to submenu or main menu
    goBack() {
        // we go to main menu list display -- full reset
        if (this.selectionStepValue === 1) {
            this.selectionStepValue = 0;

            // animate out subMenu, in menu
            const selectedMenuTarget =
                this[this.selectedMenuTargetRefValue + 'Target'];
            selectedMenuTarget.style.transform = 'translateX(100vw)';
            selectedMenuTarget.addEventListener(
                'transitionend',
                () => toggleCSSClasses(selectedMenuTarget, 'w-0', 'w-full'),
                { once: true },
            );

            this.mainListTarget.style.transform = 'translateX(0)';

            // reset header
            toggleCSSClasses(this.menuHeaderTarget, 'hidden');
            toggleCSSClasses(this.menuHeaderSelectionTarget, 'hidden', 'flex');
            this.menuHeaderSelectionNameTarget.innerText = '';
        }

        // we go back submenu -> menu
        if (this.selectionStepValue === 2) {
            this.selectionStepValue = 1;

            const selectedSubMenuTarget =
                this[this.selectedSubMenuTargetRefValue + 'Target'];

            // reset subcategory
            const subMenuElement = document.getElementById(
                this.selectedSubMenuId,
            );
            if (subMenuElement) {
                subMenuElement.style.transform = 'translateX(100vw)';
            }

            // toggle selected category
            selectedSubMenuTarget.style.transform = 'translateX(0)';

            this.menuHeaderSelectionNameTarget.innerText =
                this.selectedMenuNameValue;
        }
    }
}
